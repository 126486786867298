<template>
  <div>
    <a-form
      class="simiot-nested-search-form"
      :form="form"
      @submit="handleSearch"
    >
      <a-row :gutter="24">
        <a-col :span="8" :xxl="6">
          <a-form-item label="">
            <a-input
              v-decorator="['iccid_or_phone_number', {
                normalize: this.$lodash.trim
              }]"
              placeholder="请输入ICCID或SIMID或手机号"
              allow-clear
            />
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6" v-show="expand">
          <a-form-item label="运营商种类">
            <a-select
              v-decorator="['carrier_type']"
              allow-clear
              @change="handleCarrierTypeChange"
            >
              <a-select-option
                v-for="option in carrierTypeOptions"
                :key="option.value"
                :value="option.value"
              >
                {{ option.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6" v-show="expand">
          <a-form-item label="运营商账户">
            <a-select
              v-decorator="['carrier_account_id']"
              :loading="loadingCarrierAccountOptions"
              allow-clear
            >
              <a-select-option
                v-for="option in carrierAccountOptions"
                :key="option.id"
                :value="option.id"
              >
                {{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6" v-show="expand">
          <a-form-item label="所属客户">
            <a-select
              v-decorator="['agent_id']"
              :show-arrow="false"
              show-search
              allow-clear
              :filter-option="false"
              :not-found-content="loadingAgentOptions ? undefined : null"
              label-in-value
              placeholder="输入客户名称加载选项"
              @search="fetchAgentOptions"
              :loading="loadingAgentOptions"
            >
              <a-spin v-if="loadingAgentOptions" slot="notFoundContent" size="small" />
              <a-select-option
                v-for="option in agentOptions"
                :key="option.id"
                :value="option.id"
              >
                {{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6" v-show="expand">
          <a-form-item label="事件名称">
            <a-select
              v-decorator="['execute_type']"
              allow-clear
              :loading="loadingExecuteTypeOptions"
              @focus="fetchSimCardCarrierTaskExecuteTypeOptions"
            >
              <a-select-option
                v-for="option in executeTypeOptions"
                :key="option.slug"
                :value="option.slug"
              >
                {{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6" v-show="expand">
          <a-form-item label="事件状态">
            <a-select
              v-decorator="['status']"
              allow-clear
            >
              <a-select-option
                v-for="option in statusOptions"
                :key="option.value"
                :value="option.value"
              >
                {{ option.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6" v-show="expand">
          <a-form-item label="计划开始时间">
            <a-range-picker v-decorator="['execute_start_date']" />
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6">
          <a-form-item>
            <a-space>
              <a-button type="primary" html-type="submit">
                搜索
              </a-button>
              <a-button @click="handleReset">
                刷新
              </a-button>
              <a-button :style="{ fontSize: '12px', lineHeight: '32px' }" @click="toggle">
                更多条件
                <a-icon :type="expand ? 'up' : 'down'" />
              </a-button>
            </a-space>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>
<script>
import { formatRangeBeginDate, formatRangeEndDate } from '@/utils/time'
import {
  findCarrierTypeOptions,
  findCarrierAccountOptions,
  findSimCardCarrierTaskStatusOptions
} from '@/api/common_options'
import { findSimCardCarrierTaskExecuteTypeOptions } from '@/api/sim_card_carrier_task'
import { findAgentOptions } from '@/api/agent'
export default {
  name: 'SearchSimCardCarrierTasks',
  data() {
    return {
      expand: false,
      carrierTypeOptions: findCarrierTypeOptions(),
      carrierAccountOptions: [],
      executeTypeOptions: [],
      loadingExecuteTypeOptions: false,
      statusOptions: findSimCardCarrierTaskStatusOptions(),
      loadingCarrierAccountOptions: false,
      loadingAgentOptions: false,
      agentOptions: [],
      form: this.$form.createForm(this, { name: 'sim_card_carrier_task_search' })
    }
  },
  methods: {
    toggle() {
      this.expand = !this.expand
    },

    filterOptions(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },

    // 运营商种类变更，加载运营商账户和运营商状态
    handleCarrierTypeChange(carrierType) {
      this.loadingCarrierAccountOptions = true
      // 清空选项
      this.carrierAccountOptions = []
      // 清空选项选中的值
      this.form.setFieldsValue({
        carrier_account_id: undefined
      })

      if (carrierType) {
        findCarrierAccountOptions(carrierType).then(res => {
          if (res.code === 0) {
            this.carrierAccountOptions = res.data
          }
          this.loadingCarrierAccountOptions = false
        })
      }
    },

    // 加载所属用户选项数据
    fetchAgentOptions(name) {
      if (!this.$lodash.trim(name)) {
        return
      }

      this.loadingAgentOptions = true
      findAgentOptions({ name: name }).then(res => {
        if (res.code === 0) {
          this.agentOptions = res.data
        }
        this.loadingAgentOptions = false
      })
    },

    // 运营商接口事件名称类别
    fetchSimCardCarrierTaskExecuteTypeOptions() {
      if (this.executeTypeOptions.length === 0) {
        this.loadingExecuteTypeOptions = true
        findSimCardCarrierTaskExecuteTypeOptions().then((res) => {
          if (res.code === 0) {
            this.executeTypeOptions = res.data
          }
          this.loadingExecuteTypeOptions = false
        })
      }
    },

    handleSearch(e) {
      e.preventDefault()
      const fieldsValue = this.form.getFieldsValue()
      const values = {
        ...fieldsValue,
        agent_id: fieldsValue.agent_id && fieldsValue.agent_id.key,
        execute_start_date_begin: formatRangeBeginDate(fieldsValue.execute_start_date),
        execute_start_date_end: formatRangeEndDate(fieldsValue.execute_start_date)
      }
      this.$emit('submit', values)
    },

    handleReset() {
      this.carrierAccountOptions = []
      this.form.resetFields()
      this.$emit('submit', {})
    }
  }
}
</script>
